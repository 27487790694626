<template>
  <BaseList
    :list-query="municipalities"
    route="municipality"
    locale-section="pages.municipalities"
  >
    <template v-slot:extra="{ element: { number, county } }">
      {{ number }} ( {{ county.county }}/{{ county.country.country }} )
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MunicipalityList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      municipalities: gql`
        query municipalities {
          list: municipalities {
            id
            name: municipality
            municipality
            number
            county {
              county
              country {
                country
              }
            }
          }
        }
      `
    };
  }
};
</script>
